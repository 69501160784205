import React, { useEffect, useReducer, useRef, useState } from "react";

import { Button } from "@progress/kendo-react-buttons";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import ApiCommunicator, { useApiService } from "@selas/api-communication";
import { derender, hideLoader, render, showLoader } from "@selas/state-management";
import { newKey } from "@selas/utils";
import { History } from "history";
import { TFunction } from "i18next";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";

import { customCell, dateCell, enumMultiSelectFilterCell, GridPanel, GridPanelColumn, IGridPanelRef, IRoutedTabProps, notify, translatedCell, YesNoSwitch } from "@selas/ui-components";
import Endpoint from "../../../services/api/endpoint";
import { hasPermission } from "../../../services/authentication";
import { initialProjectState } from "../../../state";
import { projectReducer } from "../../../state/reducers";
import { IApplicationState } from "../../../store";
import { Color, Permission, ProjectStatus } from "../../../utils/enums";
import { isGridCellProps } from "../../../utils/props";
import { IOpportunity, IProject, IUser } from "../../../utils/types/models";
import OpportunityEditor from "../../editors/opportunity";
import ProjectEditor from "../../editors/project";
import CopyFromProjectDialog from "../../editors/project/copyFromProject";
import CreateFromOpportunityDialog from "../../editors/project/createFromOpportunity";
import CreateFromProjectDialog from "../../editors/project/createFromProject";
import { colorCell, colorCellClassName } from "../../global/colorCell";

export function getProjectStatusColor(props: GridCellProps | ListItemProps): Color {
	const status: string = isGridCellProps(props) ? (props as GridCellProps).dataItem.status : (props as ListItemProps).dataItem.key;

	switch (status) {
		case ProjectStatus.New:
			return Color.Blue;
		case ProjectStatus.InProgress:
			return Color.Orange;
		case ProjectStatus.Finished:
			return Color.Green;
	}
}

export function editProject(record: IProject, currentUser: IUser, reduxDispatch: Dispatch, t: TFunction, ref?: React.MutableRefObject<IGridPanelRef>): void {
	if (hasPermission(Permission.ProjectsUpdate) && (record.responsibleUserId === currentUser.id || hasPermission(Permission.ProjectsAll))) {
		const projectEditorKey: string = newKey("ProjectEditor");
		reduxDispatch(
			render(projectEditorKey, ProjectEditor, {
				recordId: record.id,
				actionButtonClicked: (close: boolean) => {
					reduxDispatch(derender(projectEditorKey));
					if (!close) {
						editProject(record, currentUser, reduxDispatch, t, ref);
					}
					if (ref && ref.current) {
						ref.current.refresh();
					}
				}
			})
		);
	} else {
		reduxDispatch(notify(t("success"), t("createProjectSucces"), "success"));
	}
}

export function editOpportunity(record: IOpportunity, currentUser: IUser, reduxDispatch: Dispatch, t: TFunction, ref?: React.MutableRefObject<IGridPanelRef>): void {
	if (hasPermission(Permission.OpportunitiesUpdate)) {
		const opportunityEditorKey: string = newKey("OpportunityEditor");
		reduxDispatch(
			render(opportunityEditorKey, OpportunityEditor, {
				recordId: record.id,
				actionButtonClicked: (close: boolean) => {
					reduxDispatch(derender(opportunityEditorKey));
					if (!close) {
						editOpportunity(record, currentUser, reduxDispatch, t, ref);
					}
					if (ref && ref.current) {
						ref.current.refresh();
					}
				}
			})
		);
	} else {
		reduxDispatch(notify(t("success"), t("createProjectSucces"), "success"));
	}
}

const Projects: React.FC<IRoutedTabProps> = (props: IRoutedTabProps) => {
	const { t } = useTranslation();
	const [showAll, setShowAll] = useState(false);
	const currentUser: IUser = useSelector((state: IApplicationState) => state.authenticationState.currentUser);
	const reduxDispatch: Dispatch = useDispatch();
	const ref: React.MutableRefObject<IGridPanelRef> = useRef<IGridPanelRef>();
	const apiService: ApiCommunicator = useApiService();
	const [projectState, projectDispatch] = useReducer(projectReducer, initialProjectState);
	const history: History = useHistory();

	useEffect(() => {
		if (projectState.filename) {
			fileDownload(projectState.downloadFile, projectState.filename);
		}
	}, [projectState.downloadFile, projectState.filename]);

	useEffect(() => {
		if (projectState.isDownloading) {
			reduxDispatch(showLoader());
		} else {
			reduxDispatch(hideLoader());
		}
	}, [projectState.isDownloading, reduxDispatch]);

	function onClickCreateProject(): void {
		const createProjectKey: string = newKey("createProject");
		reduxDispatch(
			render(createProjectKey, CreateFromOpportunityDialog, {
				close: (record: IProject) => {
					if (record) {
						editProject(record, currentUser, reduxDispatch, t, ref);
					}
					reduxDispatch(derender(createProjectKey));
				}
			})
		);
	}

	function onClickCreateOpp(): void {
		const createOpportunityKey: string = newKey("createOpportunity");
		reduxDispatch(
			render(createOpportunityKey, CreateFromProjectDialog, {
				close: (record: IOpportunity) => {
					if (record) {
						if (hasPermission(Permission.OpportunitiesRead)) {
							history.push("/work/opportunities");
						}
						editOpportunity(record, currentUser, reduxDispatch, t, ref);
					}
					reduxDispatch(derender(createOpportunityKey));
				}
			})
		);
	}

	const extraToolbarItems: React.ReactElement[] = [];

	if (hasPermission(Permission.ProjectsAdd)) {
		extraToolbarItems.push(
			<Button key="createProjectFromOpportunity" primary onClick={onClickCreateProject}>
				{t("createProjectFromOpportunity")}
			</Button>
		);
	}
	if (hasPermission(Permission.OpportunitiesAdd, Permission.ProjectsDelete)) {
		extraToolbarItems.push(
			<Button key="createOpportunityFromProject" primary onClick={onClickCreateOpp}>
				{t("createOpportunityFromProject")}
			</Button>
		);
	}
	if (hasPermission(Permission.ProjectsAll)) {
		extraToolbarItems.push(
			<span key="showAllLabel" style={{ marginRight: "10px" }}>
				{t("showAllProjects")}
			</span>,
			<YesNoSwitch key="showAllSwitch" onChange={(event: SwitchChangeEvent) => setShowAll(event.value)} />
		);
	}

	return (
		<GridPanel
			listEndpoint={showAll ? Endpoint.ProjectsList : Endpoint.MyProjectsList}
			endpoint={Endpoint.Projects}
			userGridPanelConfiguration={{
				gridKey: props.reactKey,
				configurationEndpoint: Endpoint.GridPanelConfigurations,
				filterable: true
			}}
			listUrlArguments={{ isProjectPage: true }}
			addScreen={{ screen: ProjectEditor, isAllowed: hasPermission(Permission.ProjectsAdd) }}
			editScreen={{ screen: ProjectEditor, isAllowed: hasPermission(Permission.ProjectsUpdate) }}
			delete={{ isAllowed: hasPermission(Permission.ProjectsDelete) }}
			extraToolbarButtons={extraToolbarItems}
			extraCommands={[
				{
					iconClass: "las la-file-download",
					tooltip: t("downloadProjectParticipants"),
					idAction: (id: number) => apiService.callApi(projectDispatch, Endpoint.ProjectParticipantsReport, "GET", { id })
				},
				{
					iconClass: "las la-clipboard",
					tooltip: t("copyProject"),
					recordAction: (project: IProject) => {
						const createProjectKey: string = newKey("createProject");
						reduxDispatch(
							render(createProjectKey, CopyFromProjectDialog, {
								project,
								close: (record: IProject) => {
									if (record) {
										ref.current?.refresh();
									}
									reduxDispatch(derender(createProjectKey));
								}
							})
						);
					},
					showCommand: () => hasPermission(Permission.ProjectsAdd)
				}
			]}
			ref={ref}
			filter={{
				logic: "and",
				filters: [
					{
						field: "status",
						operator: "in",
						value: [ProjectStatus.New, ProjectStatus.InProgress]
					}
				]
			}}
		>
			<GridPanelColumn
				field="status"
				title={t("projectStatus")}
				cell={customCell(colorCell(true, translatedCell(), getProjectStatusColor), colorCellClassName)}
				filterCell={enumMultiSelectFilterCell(ProjectStatus)}
				isDefault
			/>
			<GridPanelColumn field="title" title={t("title")} isDefault />
			<GridPanelColumn field="responsibleUser.fullName" title={t("projectResponsible")} isDefault />
			<GridPanelColumn field="contact.fullName" title={t("contact")} isDefault />
			<GridPanelColumn field="contact.phoneNumber" title={t("phoneNumber")} isDefault />
			<GridPanelColumn field="contact.email" title={t("email")} width="200px" isDefault />
			<GridPanelColumn field="company.name" title={t("company")} isDefault />
			<GridPanelColumn field="partner.name" title={t("partner")} isDefault />
			<GridPanelColumn field="companyContact.fullName" title={t("companyContact")} isDefault={false} />
			<GridPanelColumn field="partnerContact.fullName" title={t("partnerContact")} isDefault={false} />
			<GridPanelColumn field="opportunity.name" title={t("opportunity")} isDefault={false} />
			<GridPanelColumn field="customerPONumber" title={t("customerPONumber")} isDefault={false} />
			<GridPanelColumn field="letterOfIntent" title={t("letterOfIntent")} cell={customCell(dateCell("yyyy-MM-dd HH:mm"))} filter="date" isDefault={false} />
			<GridPanelColumn field="projectGroup.name" title={t("projectGroup")} isDefault={false} />
			<GridPanelColumn field="reportableGovernmentService.name" title={t("reportableGovernmentService")} isDefault={false} />
			<GridPanelColumn field="spread" title={t("spread")} filter="numeric" isDefault />
			<GridPanelColumn field="totalAmount" title={t("totalAmount")} filter="numeric" isDefault />
		</GridPanel>
	);
};

export default Projects;
